import { useTranslations } from '@mentimeter/i18n';
import { openPreferenceCenter } from '@mentimeter/onetrust';
import { Button } from '@mentimeter/ragnar-ui/button';

export const CookiePreferencesButton = () => {
  const t = useTranslations('common');

  return (
    <Button variant="primary" mb="space8" onClick={openPreferenceCenter}>
      {t('page_links.open_cookie_preferences')}
    </Button>
  );
};
