/* eslint-disable menti-react/filename-convention--jsx */
'use client';
import * as React from 'react';
import { HeartIcon } from '@mentimeter/ragnar-visuals';
import { getSplitData } from '@mentimeter/splitio';
import { useTranslations } from '@mentimeter/i18n';
import { H3 } from 'src/ui/typography';
import { useAppState } from 'src/appState';
import { isoCountries } from '@mentimeter/billing-address';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { NotifyForm } from './NotifyForm';
import type { FormValuesT, StatusT } from './types';

const CAMPAIGN_ID = '701TX000006kyYpYAI';

interface DownloadGatedContentT {
  fileUrl: string;
  trackingEvent: string;
  salesforceCampaignId: string;
}

export const DownloadGatedContent = ({
  fileUrl,
  salesforceCampaignId = CAMPAIGN_ID,
  trackingEvent,
}: DownloadGatedContentT) => {
  const { user } = useAppState();
  const [status, setStatus] = React.useState<StatusT>();

  const handleSubscribe = async (formData: FormValuesT) => {
    setStatus('loading');
    const country = await getSplitData(['country']);
    const isoCountry = isoCountries.find(({ key }) => country.country === key);

    try {
      const result = await fetch('/api/subscribe', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          eventName: trackingEvent,
          salesforceCampaignId,
          Office_Location__c: isoCountry?.value || 'Unknown Country',
          formData,
        }),
      });
      const data = await result.json();

      setStatus(data.status);
    } catch (e) {
      setStatus('error');
    }
  };

  const t = useTranslations('common');
  if (status === 'success') {
    return (
      <Box p="space8" alignItems="center">
        <H3 mb="space2">{t('notify_form.check_inbox')}</H3>
        {fileUrl && (
          <Button
            variant="subtle"
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="download-button"
          >
            {t('notify_form.click_to_download')}
          </Button>
        )}
      </Box>
    );
  }
  return (
    <NotifyForm
      buttonTitle={t('notify_form.send_pdf')}
      formFields={{
        email: user?.email || '',
        jobTitle: '',
        company: '',
        name: user?.name || '',
      }}
      status={status}
      handleSubscribe={handleSubscribe}
      consent
    />
  );
};

const SuccessState = () => {
  return (
    <>
      <HeartIcon color="negative" size={5} />
      <H3 textAlign="center" mb="space0">
        Thank you for subscribing!
      </H3>
    </>
  );
};

export const SubscribeToLegalPackageUpdates = () => {
  const { user } = useAppState();
  const [status, setStatus] = React.useState<StatusT>();

  const handleSubscribe = async (formData: FormValuesT) => {
    setStatus('loading');

    try {
      const result = await fetch('/api/subscribeToLegalPackageUpdates', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          eventName: 'Subscribed to legal package updates',
          email: formData.email,
        }),
      });
      const data = await result.json();
      setStatus(data.status);
    } catch (e) {
      setStatus('error');
    }
  };

  return (
    <Box width="100%" alignItems="center">
      {status === 'success' ? (
        <SuccessState />
      ) : (
        <NotifyForm
          formFields={{
            email: user?.email || '',
          }}
          buttonTitle="Subscribe"
          handleSubscribe={handleSubscribe}
          status={status}
        />
      )}
    </Box>
  );
};
