'use client';
import * as React from 'react';
import type { JSX } from 'react';
import type { Document } from '@contentful/rich-text-types';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ContentfulNextImage from 'src/components/image/ContentfulNextImage';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Bold, H2, H3, H4, Italic, P } from '../ui/typography';
import { Link } from '../ui/actions';
import type { IBlockReference } from '../../types/generated/contentful';
import { CookiePreferencesButton } from '../ui/CookiePreferencesButton';
import type { EmbeddedTableBlock } from './TableRenderer';
import TableRenderer from './TableRenderer';
import { mergeDeep } from './utils/utils';

export const options: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <P
        extend={() => ({
          ':empty': { display: 'none' },
        })}
      >
        {children}
      </P>
    ),
    [BLOCKS.HEADING_2]: (_, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (_, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (_, children) => <H4>{children}</H4>,
    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri}>{children}</Link>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, children) =>
      node.data.target.fields?.file?.url ? (
        <Link
          href={`https:${node.data.target.fields.file.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </Link>
      ) : (
        children
      ),
    [BLOCKS.UL_LIST]: (_, children) => (
      <Box
        as="ul"
        pl="space4"
        mb={['space2', 'space8']}
        extend={({ theme }) => ({
          listStyleType: 'square',
          '>li::marker': {
            color: theme.colors.positive,
            fontSize: `${theme.fontSizes[4]}px`,
          },
        })}
      >
        {children}
      </Box>
    ),
    [BLOCKS.OL_LIST]: (_, children) => (
      <Box as="ol" pl="space4" mb={['space2', 'space8']}>
        {children}
      </Box>
    ),
    [BLOCKS.LIST_ITEM]: (_, children) => {
      if (
        Array.isArray(children) &&
        React.isValidElement<{ children: React.ReactNode }>(children[0])
      ) {
        const child = children[0];
        return (
          <P as="li" mb="space2">
            {child.props.children}
          </P>
        );
      }
      return;
    },
    [BLOCKS.HR]: () => (
      <Box
        as="hr"
        width="100%"
        borderColor="border"
        borderWidth="1px"
        borderStyle="solid"
      />
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const id = node.data.target?.sys.contentType?.sys.id;
      switch (id) {
        case 'image': {
          const { fields } = node.data.target;
          if (!fields?.image.fields?.file) {
            return null;
          }
          return <ContentfulNextImage image={fields} />;
        }

        case 'table': {
          const table = node as EmbeddedTableBlock;
          return <TableRenderer table={table} />;
        }
        case 'blockReference':
          const { fields } = node.data.target as IBlockReference;

          if (fields?.referenceId === 'cookie-preferences-button') {
            return <CookiePreferencesButton />; // cookie preferences button
          }

          return null;
        default:
          return null;
      }
    },
  },
};

interface Props {
  document: Document;
  rendererOptions?: Options;
  params?: Options | undefined;
}

export function BodyRenderer({
  document,
  rendererOptions = options,
  params,
}: Props): JSX.Element {
  return documentToReactComponents(
    document,
    mergeDeep(rendererOptions, params),
  ) as JSX.Element;
}
